import { useNavigate } from '@reach/router';
import { graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { BsArrowLeftShort, BsArrowRightShort, BsSearch } from 'react-icons/bs';

import { HeroNav, Layout, SEO } from '../../components';
import { BlogPost } from '../../components/blog-post';
import { useSanityGetAllPosts } from '../../hooks';

const BlogPostTemplate = ({ data: { sanityPost } }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const { allSanityPost } = useSanityGetAllPosts();

  useMemo(() => {
    const cat = [];
    // Push all category titles to cat array
    allSanityPost?.nodes?.map((post) =>
      post.categories.map((category) => cat.push(category.title))
    );
    // Filter duplicates and sort alphabetically
    // @ts-ignore
    setCategories([...new Set(cat)].sort((a, b) => a.localeCompare(b)));
  }, [allSanityPost?.nodes]);

  const currentIndex = allSanityPost?.nodes?.findIndex(
    (post) => post.slug.current === sanityPost.slug.current
  );

  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      navigate(`/blog?search=${encodeURI(e.target.value)}`);
    }
  };

  return (
    <Layout isSEOPage>
      <SEO
        title={`${sanityPost.title} | Frontline Removals`}
        description={sanityPost.metaDescription}
      />
      <div className="px-6 py-4">
        <HeroNav />
        <div className="max-w-5xl grid-cols-6 mx-auto lg:grid">
          <h1 className="col-span-6 mt-12 text-5xl font-semibold text-center uppercase font-display">
            {sanityPost.title}
          </h1>
          <div className="flex justify-center col-span-6 mt-6">
            <p className="text-lg font-medium text-center lg:text-left">
              {sanityPost.dateCreated}
            </p>
          </div>
          <div className="col-span-4 mt-12 border-black border-solid lg:pr-8 lg:border-r">
            <div className="justify-between lg:flex">
              <div>
                {sanityPost.categories.map(({ title, id }) => (
                  <p
                    className="text-xl font-medium text-center lg:text-left"
                    key={id}
                  >
                    #{title}
                  </p>
                ))}
              </div>
              <div className="flex justify-between mt-8 space-x-10 lg:mt-0 lg:justify-start">
                {allSanityPost?.nodes[currentIndex - 1] ? (
                  <Link
                    to={`/blog/${
                      allSanityPost?.nodes[currentIndex - 1].slug.current
                    }/`}
                    className="flex items-center font-medium text-md"
                  >
                    <BsArrowLeftShort className="text-2xl" /> Previous
                  </Link>
                ) : (
                  ''
                )}
                {allSanityPost?.nodes[currentIndex + 1] ? (
                  <Link
                    to={`/blog/${
                      allSanityPost?.nodes[currentIndex + 1].slug.current
                    }/`}
                    className="flex items-center font-medium text-md"
                  >
                    Next <BsArrowRightShort className="text-2xl" />
                  </Link>
                ) : (
                  ''
                )}
              </div>
            </div>
            {sanityPost.mainImage && (
              <div className="relative h-0 mt-6 aspect-w-16 aspect-h-12">
                <div className="absolute inset-0 flex">
                  <GatsbyImage
                    fluid={sanityPost.mainImage.asset.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            )}
            <Markdown className="mx-auto mt-12 prose">
              {sanityPost.body}
            </Markdown>
          </div>
          <div className="col-span-2 mt-12 lg:pl-8">
            <h2 className="text-4xl font-semibold uppercase font-display">
              Contact our team
            </h2>
            <div className="pb-6 mt-4 space-y-3 border-b-2 border-gray-300 border-solid">
              <a
                href="tel:1800549798"
                offset="12"
                className="flex items-center justify-center whitespace-no-wrap shadow hover:shadow-md button"
              >
                Call Now
              </a>

              <Link
                to="/furniture-removal/"
                offset="12"
                className="flex items-center justify-center whitespace-no-wrap shadow hover:shadow-md button-white"
              >
                Send us an Email
              </Link>
            </div>
            <div className="pb-6 mt-4 space-y-3 border-b-2 border-gray-300 border-solid">
              <div className="flex items-center px-3 bg-white border border-black border-solid rounded-md">
                <BsSearch className="text-md" />
                <label htmlFor="search-input" className="block w-full">
                  <input
                    onKeyUp={(e) => handleSearch(e)}
                    type="search"
                    name="search"
                    placeholder="Search all blog posts"
                    id="search-input"
                    className="w-full py-2 pl-3 placeholder-black bg-white border-none outline-none form-input rounded-r-md"
                  />
                </label>
              </div>

              <label htmlFor="tag-filter" className="relative block">
                <select
                  onChange={(e) =>
                    navigate(`/blog?category=${encodeURI(e.target.value)}`)}
                  id="tag-filter"
                  className="w-full py-2 pl-3 pr-8 bg-white border border-black rounded-md form-select focus:outline-white"
                >
                  <option value="all">Select a category</option>
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
                <span className="absolute pr-1 bg-white right-1 top-2">
                  <BiChevronDown className="text-2xl" />
                </span>
              </label>
            </div>
            <div className="pb-6 mt-4 space-y-3">
              {allSanityPost?.nodes[currentIndex + 1] ||
              allSanityPost?.nodes[currentIndex + 2] ? (
                <h3 className="text-4xl font-semibold uppercase font-display">
                  Up next
                </h3>
              ) : (
                ''
              )}

              {allSanityPost?.nodes[currentIndex + 1] ? (
                <BlogPost
                  post={allSanityPost?.nodes[currentIndex + 1]}
                  filter="all"
                />
              ) : (
                ''
              )}
              {allSanityPost?.nodes[currentIndex + 2] ? (
                <BlogPost
                  post={allSanityPost?.nodes[currentIndex + 2]}
                  filter="all"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    sanityPost: PropTypes.shape({
      body: PropTypes.array.isRequired,
      mainImage: PropTypes.object.isRequired,
      title: PropTypes.string.isRequired,
      metaDescription: PropTypes.string.isRequired,
      dateCreated: PropTypes.string.isRequired,
      categories: PropTypes.array.isRequired,
    }),
  }),
  pageContext: PropTypes.shape({
    slug: PropTypes.string,
    next: PropTypes.shape({
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }),
    }),
    prev: PropTypes.shape({
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export const query = graphql`
  query PostTemplateQuery($slug__current: String!) {
    sanityPost(slug: { current: { eq: $slug__current } }) {
      dateCreated: _createdAt(formatString: "MMMM DD, YYYY")
      body
      mainImage {
        asset {
          fluid(maxWidth: 1024) {
            ...GatsbySanityImageFluid
          }
        }
      }
      title
      metaDescription
      categories {
        id: _id
        title
      }
      slug {
        current
      }
    }
  }
`;

export default BlogPostTemplate;
